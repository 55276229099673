import type { ActionFunctionArgs } from '@remix-run/node';
import { json, Outlet, useLocation, useSearchParams } from '@remix-run/react';
import { useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import { DesktopAuthModal } from '~/components/desktop-auth-modal';
import Footer from '~/partials/footer';
import Header from '~/partials/header';
import { createOrganization } from '~/services/organizations.server';
import { createEnterpriseIdSession, logout } from '~/session/session.server';

function shouldInitDesktopAuth({
  loginKey,
  pathname,
  sessionLoginKey,
  onSetItem,
}: {
  loginKey: string | null;
  pathname: string;
  sessionLoginKey: string | null;
  onSetItem: (key: string, value: string) => void;
}) {
  const cantDesktopAuth = pathname.includes('subscribe');
  if (cantDesktopAuth) {
    return false;
  }

  if (loginKey || sessionLoginKey) {
    if (loginKey) {
      onSetItem('loginKey', loginKey);
    }

    return true;
  }
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const action = formData.get('action');
  invariant(action, 'Action is required');

  if (action === 'create-organization') {
    const organizationName = formData.get('organizationName') as string;
    invariant(organizationName, 'Organization name is required');

    return await createOrganization(request, {
      orgName: organizationName,
    });
  }

  if (action === 'logout') {
    await logout(request);
  }

  if (action === 'enterprise-change') {
    const enterpriseId = formData.get('enterpriseId') as string;
    const enterpriseRole = formData.get('enterpriseRole') as string;
    const headers = await createEnterpriseIdSession({ request, enterpriseId, enterpriseRole });
    return json({}, { headers });
  }

  return null;
}

export default function AppLayout() {
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const [searchParams] = useSearchParams();
  const lgKey = searchParams.get('loginKey');
  const fullscreenRoute = location.pathname.includes('authorize') || location.pathname.includes('download');

  const initDesktopAuth = useMemo(() => {
    if (!mounted) {
      return false;
    }

    const sessionLoginKey = sessionStorage.getItem('loginKey');
    return shouldInitDesktopAuth({
      pathname: location.pathname,
      loginKey: lgKey,
      sessionLoginKey,
      onSetItem: sessionStorage.setItem,
    });
  }, [mounted, location.pathname, lgKey]);

  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    if (!initDesktopAuth) {
      return;
    }

    setShowAuthModal(true);
  }, [initDesktopAuth, lgKey]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleClose = () => {
    setShowAuthModal(false);
    sessionStorage.removeItem('loginKey');
  };

  if (fullscreenRoute) {
    return (
      <main className="mx-auto h-full">
        <Outlet />
      </main>
    );
  }

  return (
    <div className="flex h-full flex-col bg-[#fafafa]">
      <Header />
      <main role="main" className="flex flex-1 justify-center p-6">
        <div className="w-full max-w-[1300px]">
          <Outlet />
          {showAuthModal && <DesktopAuthModal onClose={handleClose} />}
        </div>
      </main>
      <Footer />
    </div>
  );
}
